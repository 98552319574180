<template>    
    <div class="content-wrapper">
        
        <div v-if="isList" class="content pt-0">
            <bo-page-title />
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0">
                                            <div class="input-group">
                                                <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :ranges="datePickerConfig.ranges" 
                                                    :opens="'right'"
                                                    @update="updateValues"
                                                >
                                                    <template v-slot:input="picker">
                                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                                </date-range-picker>
                                                <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-auto">
                                            <div class="form-group mb-0">
                                                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                            </div>
                                        </div>         

                                                           

                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>

                                        <div class="col-md-2">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk"  :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>

                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select disabled placeholder=" -- Pilih Bangsal -- " @input="doFill" v-model="filter.ranap_bangsal"  :options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>
                                                                              
                                        <div class="col-md-3">
                                            <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"  :options="mDPJPUtama" :clearable="true" label="text" :reduce="v=>v.value"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>     

                     
                
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                                <th>NO.</th>
                                <th>NO. KEDATANGAN</th>
                                <th>TANGGAL MASUK</th>
                                <th>NAMA/NO KTP</th>
                                <th>DOKTER DPJP</th>                                
                                <th>NO RM</th>
                                <th>RUANGAN</th>
                                <th>JENIS RAWAT INAP</th>
                                <th>TANGGAL LAHIR</th>    
                                <th>USIA</th>    
                                <th>PPJP</th>
                                <th>STATUS</th>
                                <th>AKSI</th>
                            </tr>
                        </thead>
                        
                        <tbody v-if="(dataList||[]).length">
                            <template v-for="(v,k) in (dataList||[])">
                                <tr :key="k" :class="(validationTime(v.aranr_reg_date) && v.aranr_have_kajian_awal_perawat != 'Y') ? 'table-warning':''">
                                    <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                    <td>
                                        <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                                    </td>
                                    <td>
                                        <div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
                                    </td>
                                    <td>
                                        <div>
                                        <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
                                        <br>
                                        {{v.ap_code||"-"}}</div>
                                        <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                                    </td>
                                    <td>{{v.bu_full_name||"-"}}</td>
                                    <td>
                                        <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                        <span>dari {{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
                                        <div class="text-success-700 font-weight-semibold">
                                            {{v.no_reg||"-"}}                                    
                                        </div>
                                    </td>
                                    <td>
                                        <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                                        <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                                    </td>
                                    <td>
                                        <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                                    </td>
                                    <td>
                                        <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                                        <span v-else> - </span>
                                    </td>
                                    <td>
                                        <div class="font-weight-semibold">
                                            {{v.ap_usia_with_ket || "-"}}
                                        </div>
                                    </td>
                                    <td>
                                        {{v.nama_ppjp||"-"}}
                                    </td>
                                    <td>
                                        <div v-if="!v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan PPJP</div>
                                        <div v-else-if="v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan Perawat Pelaksana</div>
                                        <template v-else>
                                            <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div>
                                        
                                            <template v-if="v.aranr_status == 1">
                                                <div v-if="v.aranr_is_done_perawat != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal Perawat</div>
                                                
                                                <div v-if="v.aranr_is_done_dokter != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal Dokter</div>
                                            </template>
                                            
                                        </template>
                                        
                                        <div v-if="validationTime(v.aranr_reg_date) && v.aranr_have_kajian_awal_perawat != 'Y'" v-b-tooltip.hover="'Anda telah melewati batas waktu pengisian Kajian Awal Medis (1x24 jam)'" class="badge badge-danger mb-1">Batas Waktu Kajian Awal</div>
                                    </td>
                                    <td>
                                        
                                        <a href="javascript:;" v-if="moduleRole('register_poli')" @click="regPoli(v)" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover.right title="Daftarkan Ke Poli">
                                            <i class="icon-plus-circle2"></i>
                                        </a>

                                        <a href="javascript:;" @click="cetakSuratKontrol(v)" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover.right title="Cetak Surat Kontrol">
                                            <i class="icon-file-text3"></i>
                                        </a>

                                        <a v-if="v.aranrk_file_kontrol" :href="uploader(v.aranrk_file_kontrol)" target="_blank" class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800" v-b-tooltip.hover.right title="Unduh Surat Kontrol">
                                            <i class="icon-file-text2"></i>
                                        </a>

                                        
                                        <a href="javascript:;" v-if="v.aranrk_no_kontrol" @click="hapusSuratKontrol(v)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover.right title="Hapus Surat Kontrol">
                                            <i class="icon-trash"></i>
                                        </a>
                                    </td>

                                </tr>
                            </template>
                        </tbody>
                        <tbody v-if="data.data.length == 0">
                            <tr>
                                <td colspan="99">
                                <div class="text-center">
                                    <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!data.data">   
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                    </table>

                    
                    <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
                
                <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                    class="mb-0"
                    v-model="pageNo"
                    :per-page="data.per_page"
                    :total-rows="data.total"
                />
                </b-card-footer>

            </div>
            
            <validation-observer ref="VFormSubmitPerawatPelaksana">
                <b-modal 
                    v-model="openPerawatPelaksana"
                    @ok.prevent="submitPerawatPelaksana"
                    size="lg"
                    no-close-on-backdrop
                    class="text-center"
                    :title="'Tugaskan Perawat Pelaksana'" 
                >
                    <table class="table table-sm table-bordered">
                        <thead>
                            <tr>
                            <th colspan="6">Informasi Pasien</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td colspan="2">
                                <div class="result_tab">
                                    <h4>Nama</h4>
                                    <p>{{rowEdit.ap_fullname||"-"}}</p>
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="result_tab">
                                    <h4>Tanggal Lahir / Umur</h4>
                                    <p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Ruangan</h4>
                                <p>{{rowEdit.mranb_name||"-"}}</p>
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Kamar / No. Tempat Tidur / Kelas</h4>
                                <p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Kelas Titipan</h4>
                                <p>-</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Jenis Cara Bayar</h4>
                                <p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Alergi</h4>
                                    <ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
                                        <li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
                                            {{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
                                        </li>
                                    </ul>
                                    <span v-else>Tidak ada Alergi</span>
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Diagnosa Masuk</h4>
                                <p>{{rowEdit.diagnosa||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                        </tbody>

                        <thead>
                            <tr>
                            <th colspan="6">Penugasan Perawat Pelaksana</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td colspan="3" width="40%">
                                <div>
                                <label for="selPppjp">Pilih Perawat Pelaksana</label>
                                <v-select placeholder=" -- Pilih Perawat Pelaksana -- " 
                                class="vs__multiple"
                                v-model="rowEdit.aranr_perawat_pelaksana" multiple :clearable="true" :options="mPPJP" label="text" :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span>{{ option.text }} - menangani 
                                        <span class="font-weight-semibold"> {{option.pasien}} Pasien </span></span>
                                    </template>
                                </v-select>
                                </div>
                                <VValidate 
                                    :name="'Perawat Pelaksana'" 
                                    v-model="rowEdit.aranr_perawat_pelaksana" 
                                    :rules="{required : 1}"
                                />
                            </td>
                            <td colspan="3" width="50%">
                                <div></div>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </b-modal>
            </validation-observer>

            <b-modal id="kajianPersalinan" size="md" centered title="Kajian Persalinan" hide-footer>
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <router-link class="btn_cta_persalinan" :to="{ name: 'RanapKajianMandiriSC', params: { pageSlug: dataModal.aranr_id } }"> Form Kajian Mandiri SC </router-link>
                    </div>
                    <div class="col-md-6">
                        <router-link class="btn_cta_persalinan" :to="{ name: 'RanapLaporanNifas', params: { pageSlug: dataModal.aranr_id } }"> Form Laporan Nifas</router-link>
                    </div>

                    <div class="col-md-6">
                        <router-link class="btn_cta_persalinan" :to="{ name: 'RanapCatatanPersalinan', params: { pageSlug: dataModal.aranr_id } }"> Form Catatan Persalinan</router-link>
                    </div>

                    <div class="col-md-6">
                        <router-link class="btn_cta_persalinan" :to="{ name: 'RanapLaporanPersalinan', params: { pageSlug: dataModal.aranr_id } }"> Form Laporan Persalinan</router-link>
                    </div>
                </div>
            </b-modal>

            <b-modal v-model="openPostOpname" ok-only ok-title="Tutup" size="lg" title="Daftarkan Pasien Ke Rawat Jalan">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama Dokter</th>
                                    <th>Tanggal</th>
                                    <th>Waktu Periksa</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(v,k) in (jadwalKontrol||[])" >
                                    <tr v-if="v.aranjk_place == 'RSH'" :key="k">
                                        <td>{{k+1}}</td>
                                        <td>{{v.aranjk_nama_dokter||"-"}}
                                            <small class="text-info d-block" v-if="v.is_dpjp_utama">Dokter adalah DPJP utama</small>
                                        </td>
                                        <td>{{v.aranjk_date | moment("DD MMMM YYYY")}}</td>
                                        <td>{{v.aranjk_waktu_periksa||"-"}}</td>
                                        <td>
                                            <a href="javascript:;" @click="daftarkanPoli(v)" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover.right title="Daftarkan Ke Poli">
                                                <i class="icon-plus-circle2"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-if="!(jadwalKontrol||[]).length">
                                    <td colspan="5" class="text-center">Tidak ada Data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-modal>

            <validation-observer ref="VFormKontrol">
                <b-modal v-model="suratKontrolOpen" @ok.prevent="doCetakSuratKontrol" size="lg"
                title="Cetak Surat Kontrol" ok-title="Buat Surat Kontrol">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <template>
                                    <div class="card-body p-3">
                                        <div class="form-row">
                                        
                                            <div class="col-md-4">
                                                <label>Nama Poli<small class="txt_mandatory">*</small></label>
                                                <template>
                                                    <v-select placeholder="Pilih Poli" 
                                                    @input="changeDokter($event)"
                                                    v-model="dataSuratKontrol.aranrk_poli"
                                                    :options="mPoli" label="text" :clearable="true"
                                                    :reduce="v=>v.value"></v-select>
                                                    <VValidate :name="'Nama Poli'" v-model="dataSuratKontrol.aranrk_poli" :rules="{required:1}" />
                                                </template>
                                                
                                            </div>

                                            <div class="col-md-4">
                                                <label>Nama Dokter<small class="txt_mandatory">*</small></label>
                                                
                                                <template>
                                                    <v-select placeholder="Pilih Dokter" v-model="dataSuratKontrol.aranrk_dokter"
                                                    :options="mDokter" label="text" :clearable="true"
                                                    :reduce="v=>v.value"></v-select>
                                                    <VValidate :name="'Nama Dokter'" v-model="dataSuratKontrol.aranrk_dokter" :rules="{required:1}" />
                                                </template>
                                            </div>
                                            
                                            
                                            <div class="col-md-4">
                                                <label>Tanggal Kontrol<small class="txt_mandatory">*</small></label>
                                                <div class="input-group">
                                                    <div class="input-group mb-3">
                                                        <datepicker :disabledDates="disabledDates"
                                                        input-class="form-control transparent"
                                                        placeholder="Tanggal Pemeriksaan" class="my-datepicker"
                                                        calendar-class="my-datepicker_calendar"
                                                        v-model="dataSuratKontrol.aranrk_tanggal_kontrol">
                                                        </datepicker>
                                                        <div class="input-group-append calendar-group">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                            class="icon-calendar"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <VValidate :name="'Tanggal Kontrol'" v-model="dataSuratKontrol.aranrk_tanggal_kontrol" :rules="{required:1}" />
                                            </div>
                                            <!-- -->
                                            <div v-if="(rowEdit.aranr_mcp_id == 137 || rowEdit.aranr_mcp_id == 138) && dataSuratKontrol.aranrk_no_kontrol" class="col-md-4 mt-3">
                                                <label>No Surat Kontrol<small class="txt_mandatory">*</small></label>
                                                <b-form-input type="text" v-model="dataSuratKontrol.aranrk_no_kontrol"
                                                class="form-control" disabled placeholder="No Surat Kontrol" />
                                                <!--                  
                                                <VValidate :name="'No Surat Kontrol'" v-model="dataSuratKontrol.aranrk_no_kontrol" :rules="{required:1}" />
                                                -->
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label>Catatan Kontrol<small class="txt_mandatory">*</small></label>
                                                <b-form-textarea type="text" v-model="dataSuratKontrol.aranrk_catatan_kontrol"
                                                class="form-control" rows="4" placeholder="Catatan Kontrol" />

                                                <VValidate :name="'Catatan Kontrol'" v-model="dataSuratKontrol.aranrk_catatan_kontrol" :rules="{required:1}" />
                                            </div>

                                        </div>
                                        
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </validation-observer>
        </div>

        
    </div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    const moment = require('moment')
    
    import Datepicker from 'vuejs-datepicker'


    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,Datepicker
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            isDoc(){
                return this.$route.query.isDoc
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowPasien: this.rowPasien,
                    isShowBangsal: this.isShowBangsal,
                    infoBangsal: this.infoBangsal,
                    katalogBangsal: this.katalogBangsal
                }
            },
        },
        data(){
            return {
                idKey:'aranres_id',
                datePickerConfig: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                },

                rowEdit : {},
                dataModal: {},
                rowPasien : {},
                rowAdditional: {},
                mRanapBangsal : [],
                mPPJP: [],
                openPerawatPelaksana: false,
                mDPJPUtama: [],
                openPostOpname: false,
                jadwalKontrol: [],

                suratKontrolOpen: false,
                dataSuratKontrol: {},
                mDokter: [],
                mPoli: [],
            }
        },
        mounted(){
            this.filter.status_jaminan = 'ALL'
            this.filter.cara_masuk = 'ALL'
            this.filter.ranap_bangsal = this.user.bu_bangsal_id
            
            this.doConvertDate()      
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            changeDokter(e){
                this.loadingOverlay = true
                let data = {
                    type : "change-dokter",
                    id : e
                }
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    this.loadingOverlay = false
                    this.mDokter = res.data.mDokter
                })
            },
            doCetakSuratKontrol(){
                this.$refs['VFormKontrol'].validate().then(success=>{
                    if(!success){
                        return this.$swal({
                            icon: 'error',
                            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                        })
                    }else{
                        this.loadingOverlay = true
                        let data = this.dataSuratKontrol
                        data.aranrk_aranr_id = this.rowEdit.aranr_id
                        data.type = 'post-surat-kontrol'
                        data.isBPJS = (this.rowEdit.aranr_mcp_id == 137 || this.rowEdit.aranr_mcp_id == 138) ? true : false
                        Gen.apiRest(
                            "/do/"+this.modulePage,
                            {data: data}, 
                            "POST"
                        ).then(res=>{
                            this.loadingOverlay = false
                            this.suratKontrolOpen = false
                            this.doCetakSK()
                        }).catch(err => {
                            this.loadingOverlay = false
                            if (err) {
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })

                    }
                })
            },
            cetakSuratKontrol(row){
                this.rowEdit = row
                this.loadingOverlay = true
                let data = row
                data.type = 'get-surat-kontrol'
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    this.loadingOverlay = false
                    this.suratKontrolOpen = true
                    this.mDokter = res.data.mDokter
                    this.mPoli = res.data.mPoli
                    this.dataSuratKontrol = res.data.dataSuratKontrol
                })
            },
            doCetakSK(){
                let data = {exptype: 'xlsx', type: "export", id: this.rowEdit.aranr_id}
                let self = this    
                self.loadingOverlay = true
                let path
                if(this.rowEdit.aranr_mcp_id == 137 || this.rowEdit.aranr_mcp_id == 138){
                    path = "downloadSuratKontrolOpnameBPJS"
                }else{
                    path = "downloadSuratKontrolOpname"
                }
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/report/${path}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `Laporan-Rencana-Kontrol-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            regPoli(row){
                this.loadingOverlay = true
                let data = row
                data.type = 'get-jadwal-kontrol'
                
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.loadingOverlay = false
                    this.openPostOpname = true
                    this.jadwalKontrol = resp.jadwalKontrol
                    this.rowEdit = row
                })
            },
            daftarkanPoli(row){
                this.$swal({
                    title: `Apakah Anda Yakin Akan Mendaftarkan Pasien Ke Poli Klinik`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {
                        this.loadingOverlay = true
                        let data = {
                            type : "register-poli",
                            ap_id : this.rowEdit.aranr_ap_id,
                            dokter_id : row.aranjk_dokter,
                            tanggal : row.aranjk_date,
                            waktu_periksa : row.aranjk_waktu_periksa
                        }
                        
                        Gen.apiRest(
                            "/do/"+this.modulePage,
                            {data: data}, 
                            "POST"
                        ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200

                            this.$swal({
                                title: "Berhasil Mendaftarkan Pasien",
                                icon: "success",
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => { 
                                if(result.value){
                                    this.openPostOpname = false
                                    this.$router.push({ name: 'RoReservasi', params: { pageSlug: resp.data.arres_id } })
                                    .catch(()=>{})
                                }
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        })
                    }
                })
            },
            openModalKajianPersalinan(data){
                this.$bvModal.show('kajianPersalinan')
                this.$set(this, 'dataModal', data)
            },
            toMonitoring(regID){
                this.$router.push({ name: 'RanapMonitoring', params: {pageSlug: regID}}).catch(()=>{})
            },
            submitPerawatPelaksana(){
                this.$refs['VFormSubmitPerawatPelaksana'].validate().then(success=>{
                    if(!success){
                        return this.$swal({
                            icon: 'error',
                            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                        })
                    }else{
                        this.loadingOverlay = true
                        let data = this.rowEdit
                        data.type = 'assign-perawat-pelaksana'
                       
                        Gen.apiRest(
                            "/do/"+this.modulePage,
                            {data: data}, 
                            "POST"
                        ).then(res=>{
                            let resp = res.data
                            this.loadingOverlay = false
                            this.$swal({
                                title: resp.message,
                                icon: "success",
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if(result.value){
                                    this.openPerawatPelaksana = false
                                    this.apiGet()
                                }
                            })
                        })
                    }
                })
            },
            tugasKanPPJP(v){
                this.rowEdit = v
                let data = this.rowEdit
                data.type = 'get-data-alergi-diagnosa'
                this.loadingOverlay = true                 

                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.openPerawatPelaksana = true
                    this.loadingOverlay = false     
                    this.rowEdit.alergi = resp.alergi
                    this.rowEdit.diagnosa = resp.diagnosa     

                })

            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            validationTime(regDate){
                if(moment().format('YYYY-MM-DD') >= this.to24Hours(regDate)){
                    return true
                }else{
                    return false
                }
            },
            to24Hours(date){
                return moment(date).add(24, 'hours').format('YYYY-MM-DD')
            },
            initKajianAwal(row){
                this.createKajianAwal(row)                    
            },  
            createKajianAwal(row){
                this.loadingOverlay = true
                let data = {
                    idPasien : row.aranr_ap_id,
                    regId : row.aranr_id,
                    dokterId : row.aranr_dokter_id,
                    dokterRaber : row.aranr_dokter_raber_id,
                    jenisRawatInap : row.aranr_jenis_ranap
                }
                data.type = 'init-kajian-awal'
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.loadingOverlay = false
                    this.$router.push({ name: resp.menuKajian, params: {pageSlug: row.ap_id, rmNo: resp.aranrm_id}, query: {regId: row.aranr_id} }).catch(()=>{})
                })
            },
            lihatKajian(row){
                this.$router.push({ name: this.toMenuKajianAwal(row.aranr_kajian_awal_type), params: {pageSlug: row.ap_id, rmNo: row.aranrm_id}, query: {regId: row.aranr_id} }).catch(()=>{})
            },
            hapusSuratKontrol(row){
                this.$swal({
                    title: 'Hapus Data Rencana Kontrol?',
                    text: "Anda tidak akan dapat memulihkan data ini!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, Hapus!',
                    cancelButtonText: 'Kembali',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                      this.loadingOverlay = true
                      let data = {
                        id: row.aranr_id,
                        type: 'delete-rencana-kontrol',
                        noSK: row.aranrk_no_kontrol
                      }
                      data.isBPJS = (this.rowEdit.aranr_mcp_id == 137 || this.rowEdit.aranr_mcp_id == 138) ? true : false
                      
                      Gen.apiRest(
                          "/do/"+this.modulePage,
                          {data: data}, 
                          "POST"
                      ).then(res=>{
                          let resp = res.data
                          this.loadingOverlay = false
                          this.$swal({
                              title: resp.message,
                              icon: "success",
                              confirmButtonText: 'Ok',
                              allowOutsideClick: false,
                              allowEscapeKey: false
                          }).then(result => {
                              if(result.value){
                                  this.apiGet()
                              }
                          })
                      }).catch(err => {
                          this.loadingOverlay = false
                          if (err) {
                              err.statusType = err.status
                              err.status = "error"
                              err.title = err.response?.data?.title
                              err.message = err.response?.data?.message
                              err.messageError = err.message
                          }
                          this.doSetAlertForm(err)
                      })
                    }
                })
            
            },

        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                
                if(this.isList){
                    this.apiGetResume()
                }
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            },
            'openPerawatPelaksana':function(v){
                if(!v){
                    this.apiGet()
                }
            }
        }
    }
</script>